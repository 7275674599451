<script lang="ts" setup>
import { CfgIssuesCard } from '@cfgtech/ui'
import { ISSUES_SLUG_ID_MAP } from '~/constants/issuesSlugIdMap'
import { ROUTES } from '~/constants/routes'
import type { IssueList } from '~/services/generated/client/public'

const { issue } = defineProps<{ issue: IssueList }>()

const {
  collaterals,
  periodicityFraction,
  interestRateFormatted,
  progress,
  info,
  header,
  monthsUntilMaturity,
  maturityLabel,
} = useIssuesDataCommon(issue)
const responsive = useResponsive()

const isMobile = computed(() => {
  return responsive.value.mobile || responsive.value.tablet
})

const mobileCalculatorOpened = useState('mobileCalculatorOpened', () => false)
const scrollToCalculator = useState('scrollToCalculator', () => false)

async function handleOpenCalculator() {
  if (isMobile.value) {
    mobileCalculatorOpened.value = true
  }
  scrollToCalculator.value = true

  await navigateTo(`${ROUTES.ISSUE}/${getIssueSlug(issue.id)}`)
}
const config = useRuntimeConfig()

const issueIdSlugMap = computed(() => {
  const env = config.public.environment === 'production' ? 'production' : 'dev'
  return Object.entries(ISSUES_SLUG_ID_MAP[env]).reduce((acc, [slug, id]) => {
    acc[id] = slug
    return acc
  }, {} as Record<number, string>)
})

function getIssueSlug(issueId: number) {
  return issueIdSlugMap.value[issueId] ?? issueId
}
</script>

<template>
  <CfgIssuesCard
    :key="issue.id"
    :button-label="$t('issue.detailButton')"
    :collaterals
    :header
    :info
    :interest-rate-formatted
    :issue-url="`${ROUTES.ISSUE}/${getIssueSlug(issue.id)}`"
    :maturity-label
    :months-until-maturity
    :name="issue.projectName || issue.name"
    :periodicity-fraction="periodicityFraction"
    :progress="progress"
    @open-calculator="handleOpenCalculator"
    @open-issue="navigateTo(`${ROUTES.ISSUE}/${getIssueSlug(issue.id)}`)"
  >
    <template #collateralsCta>
      <IssuesCtaBanner />
    </template>
  </CfgIssuesCard>
</template>

<style>
.button.issue-card-button {
  --padding-y: 0.375rem;
  --padding-x: 0.375rem;
}
</style>
